<template>
  <div
    class="_cont"
    v-if="src2"
    :class="{ ltr: lang == '2', rtl: lang != '2' }"
    :dir="lang == '2' ? 'ltr' : 'rtl'"
  >
    <h4 v-if="index" class="_index" :dir="lang == '2' ? 'ltr' : 'rtl'">
      <span>{{ index }}</span>
      <span>-</span>
    </h4>
    <div class="ques-prev">
      <div v-if="type == 'text'">
        <h4>{{ src2 }}</h4>
      </div>
      <!----------------------------------------------------->
      <!----------------------------------------------------->
      <!----------------------------------------------------->

      <div v-if="type == 'image'" class="img-preview">
        <!-- <cld-image v-if="public_id" :publicId="public_id"> -->
        <!-- <cld-transformation angle="-45" /> -->
        <!-- <cld-transformation crop="pad" /> -->
        <!-- </cld-image> -->
        <img
          class=""
          @click="onImageError"
          @error="onImageError"
          :src="src2.replace('http://', 'https://')"
          loading="lazy"
        />
      </div>
      <!----------------------------------------------------->
      <!----------------------------------------------------->
      <!----------------------------------------------------->
      <div v-if="type == 'video'" class="">
        <iframe
          :src="src2 + '?controls=0;frame=0;autoplay=1'"
          style="amx-width: 600px"
          height="300px"
          width="100%"
          frameborder="0"
        ></iframe>
      </div>
      <!----------------------------------------------------->
      <!----------------------------------------------------->
      <!----------------------------------------------------->

      <div v-if="type == 'audio'">
        <audio controls>
          <source :src="src2" />
        </audio>
      </div>
      <!----------------------------------------------------->
      <!----------------------------------------------------->
      <!----------------------------------------------------->
      <div v-if="type == 'html'">
        <div v-html="src2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["src", "lang", "index"],
  created() {
    this.src2 = this.src;
    // const arr = this.src.split(".");
    // const ext = arr[arr.length - 1];
    // const url = this.src.split(ext)[0];
    // this.public_id = url + "webp";
  },
  data() {
    return {
      public_id: null,
      type: "image",
      src2: "",
    };
  },
  watch: {
    src(val) {
      this.src2 = val;
    },
    src2(val) {
      if (val.includes("image-HH-")) this.type = "image";
      else if (val.includes("embed")) this.type = "video";
      else if (val.includes("http")) this.type = "image";
      else this.type = "text";
    },
  },
  methods: {
    onImageError(e) {
      setTimeout(function () {
        const src = e.target.src;
        e.target.src = src;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
._cont {
  height: 100%;
  width: 100%;
}
.ques-prev {
  // display: inline;
  height: 100%;
  // vertical-align: center;
}
.img-preview {
  background: red;
  width: 500px;
  height: 450px;
  // min-height: 300px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100% !important;
    width: 100% !important;
    object-fit: fill;
  }
}

._index {
  display: inline;
}
</style>
