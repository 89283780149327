<template>
  <div>
    <div class="">
      <div class="d-flex justify-content-center">
        <v-radio-group v-model="contentType" row>
          <v-radio color="success" label="text" value="text">
            <template v-slot:label>
              <strong style="color: #000">Image Url</strong>
            </template>
          </v-radio>
          <!---->
          <v-radio color="success" label="text" value="image">
            <template v-slot:label>
              <strong style="color: #000">Image</strong>
            </template>
          </v-radio>
          <!---->
          <v-radio color="success" label="text" value="video">
            <template v-slot:label>
              <strong style="color: #000">Video</strong>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <hr />
      <!---------------------------------------->
      <div v-if="contentType == 'text'">
        <textarea
          v-model="text"
          placeholder="The Question"
          class="form-control"
          rows="3"
        ></textarea>
      </div>
      <!---------------------------------->
      <!---------------------------------->
      <!---------------------------------->
      <div v-if="contentType == 'image'" class="center">
        <div class="p-1">
          <input style="display: none" type="file" ref="pick" @change="pickFile" />
        </div>
        <div class="image" style="position: relative">
          <display-question v-if="text.includes('http')" :src="text" />
          <!----------------------------------------->
          <div v-if="!text.includes('http')" class="placeholder">
            <span v-if="loading">uploading...</span>
            <img
              @click="$refs.pick.click()"
              class="img-fluid"
              src="../assets/imgs/imageholder.jpg"
            />
          </div>
        </div>
      </div>
      <!------------------------------------------------------------>
      <!------------------------------------------------------------>
      <!------------------------------------------------------------>

      <div v-if="contentType == 'video'">
        <label for="yt" class="form-label">Put youtube url</label>
        <input
          class="form-control form-control-lg"
          id="yt"
          type="text"
          :value="text"
          @change="setEmbedUrl"
        />
        <!--------------------------------------------->
        <div class="row justify-content-center p-2">
          <div class="col-md-6">
            <iframe
              width="100%"
              style
              controls
              v-if="text.includes('embed')"
              :src="text"
            />
          </div>
        </div>
      </div>
      <!--------------------------------------------->
      <!--------------------------------------------->
      <!--------------------------------------------->
      <div class="p-2 mt-3">
        <span class="text-center" v-if="loading">uploading...</span>
        <!----------------------------------------->
        <div v-if="progress > 0" class="">
          <h5 class="text-center">{{ progress }}%</h5>
          <div class="center">
            <progress :value="progress" max="100">
              {{ progress }}
            </progress>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="col-md-4 center">
            <button @click="removeFile" class="btn btn-outline-danger">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
// import { eventBus } from "../main";
import { delay, setEmbedUrl } from "../functions";
import DisplayQuestion from "./display-question";
export default {
  props: ["content"],
  //events: [
  //getQuestionText (text, contentType) to question.vue & questionSection.vue
  // ]
  components: {
    DisplayQuestion,
  },
  created() {
    this.text = this.content || "";
    console.log(this.text);
  },
  mounted() {
    setInterval(() => {
      this.$emit("getText", this.text);
    }, 700);
    // eventBus.$on("collectText", () => {
    //   this.$emit("getText", this.text);
    // });
  },
  data() {
    return {
      loading: false,
      text: "",
      contentType: "text",
      progress: 0,
      file: null,
      debounceTimer: null,
    };
  },
  //////////////////////////////////////////////////////////////
  watch: {
    content(val) {
      this.text = val;
    },
    text(val) {
      if (val.includes("image-HH-")) this.contentType = "image";
      else if (val.includes("embed")) this.contentType = "video";
      else if (val.includes("http")) this.contentType = "image";
      else this.contentType = "text";
    },
    question(val) {
      this.text = val || "";
    },
  },
  computed: {
    public_id() {
      return `jannah/image-HH-${new Date().getTime()}`;
    },
  },
  /////////////////////////////////////////////////////////////
  methods: {
    removeFile() {
      this.text = "";
    },
    pickFile() {
      let allowed = ["jpg", "jpeg", "png", "svg", "webp"];
      if (this.contentType == "audio") allowed = ["mpeg"];
      const file = event.target.files[0];
      if (!allowed.includes(file.type.split("/")[1])) {
        alert("invalid file format");
        return;
      }
      if (file.size > 7000000) {
        alert("file is large try smaller one");
        return;
      }
      this.file = file;
      this.uploadFile();
    },
    ////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////
    async uploadFile() {
      await delay(400);
      this.progress = 0;
      this.loading = true;
      this.contentType = "image";
      var fd = new FormData();
      fd.append("upload_preset", "lkgl4col");
      fd.append("tags", this.public_id);
      console.log(this.file);
      fd.append("file", this.file);
      var url = `https://api.cloudinary.com/v1_1/farghaly-developments/upload?public_id=${this.public_id}`;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      xhr.upload.addEventListener("progress", this.setProgress);

      xhr.onreadystatechange = () => {
        this.progress = 0;
        this.loading = false;
        if (xhr.onerror) xhr.onerror = (xhr, (e) => console.log(e));
        if (xhr.readyState == 4 && xhr.status == 200) {
          // File uploaded successfully
          var response = JSON.parse(xhr.responseText);
          var url = response.secure_url;
          this.text = url;
        } else {
          // this.$store.dispatch("writemessage", "try again...");
        }
      };
      xhr.send(fd);
    },
    ///////////////////////////////////////////////

    setProgress(e) {
      this.progress = Math.round((e.loaded * 100.0) / e.total);
    },
    setEmbedUrl(e) {
      this.text = setEmbedUrl(e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  text-align: center;
}
.placeholder {
  position: relative;
  span {
    position: absolute;
    top: 49%;
    left: 40%;
  }
}
.audio {
  display: block;
  width: 100%;
  audio {
    max-width: 100%;
    max-height: 100%;
    margin: 2px auto;
    object-fit: contain;
  }
}
</style>
