export const delay = async(time) => {
    return new Promise((res) => {
      setTimeout(() => {
        console.log("1000 sec")
        res();
      }, time);
    })
  }

  export const setEmbedUrl =(val) => {
      ////////////////////////////////////////////////////
      if (!val || val == "" || val == "null" || val == undefined || val == "undifined")
        return "";
      if (val.includes("vimeo")) return val;
      const slashes = val.split("/");
      const length = slashes.length;
      let id = "";
      if (val.includes("youtube") && val.includes("/watch")) {
        id = val.split("v=")[1];
      } else if (
        val.includes("youtube") &&
        (val.includes("/shorts") || val.includes("/video")) &&
        val.includes("/edit")
      ) {
        id = slashes[length - 2];
      } else if (
        val.includes("youtube") &&
        (val.includes("/shorts") || val.includes("/video") || val.includes("/embed"))
      ) {
        id = slashes[length - 1];
      } else if (val.includes("youtu.be")) {
        id = slashes[length - 1];
      } else {
        return "";
      }
      id = id.split("?").length > 0 ? id.split("?")[0] : id;
      return `https://www.youtube.com/embed/${id}`;
  }